import styles from "./app.module.scss";
import { MachineSelectorButton } from 'ui';
import classNames from 'classnames';


const model = window.app.preloadState.explodedView;
const vehicle = window.app.preloadState.selectedVehicle;

function App() {

    if (model?.showMachineNotSelectedMessage) {
        const phrase = model.machineNotSelectedMessage;
        return <div className={classNames(styles.wrapper, styles.explodedViewPage)}>
            <div className="m-top--x3" dangerouslySetInnerHTML={{ __html: phrase }}></div>
            <MachineSelectorButton vehicle={vehicle} />
        </div>;
    }
    if (model?.showNoCatalogsMessage) {
        const phrase = model.catalogNotFoundMessage;
        return <div className={styles.wrapper}>
            <div className="m-top--x3" dangerouslySetInnerHTML={{ __html: phrase }}></div>
        </div>;
    }

    return <div className={styles.wrapper}>
        <div className="sparePartAccordionsContainer adjustedDesignContainer">
            {model?.catalogs.map(catalog => <div className="sparePartsAccordion">
                    <a className="sparePartsAccordionTitle" href={catalog .url}>
                        <span title={catalog.displayName}>{catalog.displayName}</span>
                    </a>
                </div>
            )}
        </div>
    </div>
}

export default App;